import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Wrapper from '../layouts/wrapper'
import Button from '../layouts/button'
import BgCurve from '../svgs/background-curve.svg'
import Illustration from '../svgs/hero-header-illustration.svg'

const PageSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 90px;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

const PageContent = styled.article`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: 1024px) {
    display: block;
    text-align: left;
  }
`

const Heading = styled.h1`
  margin-bottom: 12px;
  font-family: 'Pacifico', serif;
  font-size: 5rem;
  font-weight: normal;
  line-height: 1;

  @media (min-width: 768px) {
    font-size: 7.5rem;
  }
`

const SubHeading = styled.p`
  margin-bottom: 24px;
  max-width: 380px;
  font-size: 1.125rem;
  
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`

const BgCurveWrapper = styled.div`
  display: none;
  
  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    /* width: 664px; */
    width: 50%;
  }

  @media (min-width: 1920px) {
    top: -5vw;
  }

  @media (min-width: 2200px) {
    display: none;
  }
`

const IllustrationWrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 162px;
    right: 0;
    z-index: 1;
    width: 560px;
  }

  @media (min-width: 1200px) {
    left: 50%;
    width: 40%;
    max-width: 768px;
  }
`

const HomeHeroHeader = ({ heading, subheading }) => (
  <PageSection>
    <Wrapper>
      <PageContent>
        <Heading>{heading}</Heading>
        <SubHeading>{subheading}</SubHeading>
        <Button mod='primary' to='/work/'>
          See our work
        </Button>
      </PageContent>
    </Wrapper>
    <BgCurveWrapper>
      <BgCurve />
    </BgCurveWrapper>
    <IllustrationWrapper>
      <Illustration />
    </IllustrationWrapper>
  </PageSection>
)

HomeHeroHeader.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

HomeHeroHeader.defaultProps = {
  heading: ``,
  subheading: ``,
}

export default HomeHeroHeader
