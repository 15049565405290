import { StaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Img from 'gatsby-image'

import Wrapper from '../layouts/wrapper'
import Section from '../layouts/section'
import IconLink from '../layouts/IconLink'

const Heading = styled.h2`
  margin-bottom: 48px;
  font-family: 'Pacifico', serif;
  font-size: 3rem; 
  font-weight: normal;
  line-height: 1;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`

const WorkList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Work = styled.article`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* margin-left: auto;
  margin-right: auto; */

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &:not(:last-child) {
    margin-bottom: 48px;

    @media (min-width: 768px) {
      margin-bottom: 60px;
    }
  }

  &:before {
    position: absolute;
    z-index: -1;
    width: 90px;
    height: 90px;
    border-color: #FF7C6E;
    border-style: solid;
    border-width: 6px 0 0 6px;

    @media (min-width: 768px) {
      content: '';
    }
  }

  > .gatsby-image-wrapper {
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: 480px) {
      width: 400px;
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &:nth-child(odd) {
    &:before {
      top: -6px;
      left: -6px;
    }

    > .gatsby-image-wrapper {
      @media (min-width: 768px) {
        margin-right: 48px;
      }
    }
  }

  &:nth-child(even) {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }

    &:before {
      bottom: -6px;
      right: -6px;
      border-width: 0 6px 6px 0;
    }

    > .gatsby-image-wrapper {
      @media (min-width: 768px) {
        margin-left: 48px;
      }
    }
  }
`

const WorkContent = styled.div`
  max-width: 320px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }

  ${Work}:nth-child(even) > & {
    @media (min-width: 768px) {
      text-align: right;
    }
  }

  > h3,
  p {
    margin-bottom: 12px;
  }
`

const WorkHeading = styled.h3`
  font-size: 24px;
  line-height: 1.25;
`

const FeaturedWorks = ({ works }) => {

  const featuredWorkList = works.map(work => {

    const {
      slug
    } = work.node.fields

    const {
      title,
      client,
      website,
      featuredImg
    } = work.node.frontmatter
    
    return(
      <Work key={title}>
        <Img fluid={featuredImg.childImageSharp.fluid} />
        <WorkContent>
          <WorkHeading>{title}</WorkHeading>
          <p>{client}</p>
          <IconLink mod='primary' to={slug}>
            View case study
          </IconLink>
        </WorkContent>
      </Work>
    )
  })

  
  return (
    <Section paddedTop paddedBottom>
      <Wrapper>
        <div>
          <Heading>Featured works</Heading>
          <WorkList>
            {featuredWorkList}
          </WorkList>
        </div>
      </Wrapper>
    </Section>
  )
}

export default FeaturedWorks
