import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Wrapper from '../layouts/wrapper'
import Section from '../layouts/section'
import IconLink from '../layouts/IconLink'

import SVGWebDesign from '../svgs/service-blurb-web-design.svg'
import SVGMaintenance from '../svgs/service-blurb-maintenance.svg'
import SVGSeo from '../svgs/service-blurb-seo.svg'

const Heading = styled.h2`
  margin-bottom: 48px;
  font-family: 'Pacifico', serif;
  font-size: 3rem; 
  font-weight: normal;
  line-height: 1;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 1024px) {
    text-align: left;
  }
`

const ServiceGrid = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 48px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ServiceItem = styled.article`
  max-width: 260px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }

  > svg {
    margin-bottom: 24px;
    height: 120px;
  }

  > h3 {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.25;
  }

  > p {
    margin-bottom: 12px;
    /* margin-bottom: 24px; */
  }
`

const ServiceBlurbs = ({ services }) => {
  
  const serviceList = services.map(({heading, excerpt, url}) => 
    <ServiceItem key={heading}>
      {heading === 'Web design and development' ? <SVGWebDesign /> : null}
      {heading === 'Website maintenance plans' ? <SVGMaintenance /> : null}
      {heading === 'Search Engine Optimization' ? <SVGSeo /> : null}
      <h3>{heading}</h3>
      <p>{excerpt}</p>
      <IconLink mod='primary' to='/contact/'>
        Free quote
      </IconLink>
    </ServiceItem>
  )
  
  return (
    <Section bgGradient paddedTop paddedBottom>
      <Wrapper>
        <div>
          <Heading>What we do</Heading>
          <ServiceGrid>
            {serviceList}
          </ServiceGrid>
        </div>
      </Wrapper>
    </Section>
  )
}

export default ServiceBlurbs
