import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
//import Image from '../components/image'
import SEO from '../components/seo'

import HomeHeroHeader from '../components/home-hero-header'
import ServiceBlurbs from '../components/service-blurbs'
import FeaturedWorks from '../components/featured-works'
import CallToAction from '../components/call-to-action'

const IndexPage = ({ data, location }) => {

  const {
    heroheader,
    services
  } = data.page.meta
  
  return (
    <Layout location={location.pathname}>
      <SEO title="DesignWeb | Web design, development and SEO based in Auckland, NZ" />
      <HomeHeroHeader 
        heading={heroheader.heading} 
        subheading={heroheader.subheading}
      />
      <ServiceBlurbs services={services} />
      <FeaturedWorks works={data.works.edges} />
      <CallToAction />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomepageQuery {
    page: markdownRemark(
      fileAbsolutePath: {regex: "\/pages/"}
      frontmatter: {template: {eq: "home"}}
    ) {
      meta: frontmatter {
        title
        heroheader {
          heading
          subheading
        }
        services {
          heading
          excerpt
          url
        }
      }
    }
    works: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "\/work/"}
        frontmatter: {featured: {eq: true}}
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            client
            website
            featuredImg {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;